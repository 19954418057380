<template>
  <div class="animated-background"></div>
</template>

<script>
export default {
  data: function() {
    return {}
  }
}
</script>

<style lang="scss" scoped>

@keyframes placeHolderShimmer {
  0%{ background-position: -468px 0; }
  100%{ background-position: 468px 0; }
}

.animated-background {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeHolderShimmer;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 30px;
  width: 350px;
  position: relative;
  margin-bottom: 1rem;
}

</style>