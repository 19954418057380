<template>
  <div>
    <div class="form-group row mb-2">
      <label class="col-sm-2 col-form-label">Name</label>
      <div class="col-sm-10">
        <input type="text" v-model="search.name" placeholder="Search Name" @input="changed" class="form-control" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ["name"],
    data() {
      return {
        search: {
          name: ""
        }        
      }
    },
    mounted() {
      this.search.name = this.name
    },
    methods: {
      changed(){        
        this.$emit('change', this.search)
      }
    }
  }
</script>