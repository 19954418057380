<template>
  <div class="mb-2">
    <div class="float-start ">
      <AnimatedPlaceholder v-if="isFetching" />
      <h5 v-else>Showing {{this.propertyCount}} of {{commaDelimited(this.totalPropertyCount)}} CMBS Properties</h5>
    </div>
    
    <div class="float-start">
      <Pillbar/>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import AnimatedPlaceholder from './AnimatedPlaceholder.vue'
import Pillbar from './Pillbar.vue'

export default {
  components: {
    AnimatedPlaceholder,
    Pillbar
  },
  props: {
    propertyCount: Number,
    totalPropertyCount: Number
  },
  data() {
    return {
      filters: []
    }
  },
  computed: {
    ...mapFields([
      'isFetching'
    ])
  },
  methods: {
    commaDelimited(value) {
      if (value == undefined || value == null) {
        return value;
      }
    
      return value.toLocaleString();
    }
  },
}
</script>