<template>
  <div>
    <div class="mt-3">
      <h4>Property Owner</h4>
    </div>
    <div>    
      <form @submit.prevent="createContact">
        
        <div class="mt-2">
          <b>Company Name</b> ( use this to find the lead in Close )
          <input v-model="contact.company_name" @input="debouncedSearch" type="text" name="name" class="form-control" />
        </div>
        
        <div class="mt-3">
          <b>Name</b>
          <input v-model="contact.name" type="text" name="name" class="form-control">
        </div>
        
        <div class="mt-3">
          <b>Title</b>
          <input v-model="contact.title" type="text" name="title" class="form-control">
        </div>

        <div class="mt-2">
          <b>Email</b><span> (Required to save a Contact in CRM)</span>
          <input v-model="contact.email" type="text" name="email" class="form-control" required>
        </div>
    
        <div class="mt-2">
          <b>Office Phone Number</b>
          <input v-model="contact.phone_number" type="text" name="phone_number" class="form-control">
        </div>
        
        <div class="mt-2">
          <b>Mobile Phone Number</b>
          <input v-model="contact.mobile_phone_number" type="text" name="mobile_phone_number" class="form-control">
        </div>
      
        <div class="mt-2">
          <b>Website</b>
          <input v-model="contact.website" type="text" name="website" class="form-control">
        </div>
        
        <div class="mt-2">
          <b>Address</b>
          <input v-model="contact.address" type="text" name="address" class="form-control">
        </div>
    
        <button type="submit" name="commit" class="btn btn-primary mt-2">Submit</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from 'vue-multiselect'
import { debounce } from 'lodash';

import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

export default {
  components: {
    Multiselect
  },
  props: ['initialContact'],
  data() {
    return {
      contact: { ...this.initialContact },
      leads: [],
    }
  },
  created() {
    this.leadAbortController = new AbortController()
    this.debouncedSearch = _.debounce(this.searchCompany, 300)
  },
  watch: {
    company_name: function(newVal, oldVal){
      console.log(oldVal);
      console.log(newVal);
    }
  },
  methods: { 
    searchCompany: function() {
      console.log("Searching for company...");
      
      axios.get(`/crm/leads/search?name=${this.contact.company_name}`, {})
      .then(response => {
        console.log(response.data.map(lead => lead.name))
        this.leads = response.data.map(lead => lead.name)
      })
    },
    createContact: function() {
      const notyf = new Notyf({
        position: {
          x: 'center',
          y: 'top',
        }
      });
      
      const propertyId = this.$route.params.id;
      
      axios.post(`/properties/${propertyId}/contacts.json`, { contact: this.contact })
      .then(response => {
        notyf.success('Property contact updated!');
        this.contact = response.data
        this.$emit("updateDone", this.contact)
      })
      .catch(error => {
        notyf.error('Oops, property contact could not be updated');
        this.$emit("updateDone")
      })
    },
  }
}
</script>