<template>
  <div>
    <h4 class="mb-3 mt-4">Placer Rankings</h4>
    
    <div class="mt-4 text-center" v-if="!loaded">
      Fetching ranking...
      <Spinner/>
    </div>
    <div class="mt-4" v-else-if="entitiesWithRanking.length == 0">
      <div>
        No Available Ranking
      </div>
    </div>
    <div v-else>
      <table class="table">
        <tbody>
          <tr>
            <td></td>
            <td>{{entitiesWithRanking[0].ranking.nationwide.regionCode}}</td>
            <td>{{entitiesWithRanking[0].ranking.state.regionCode}}</td>
          </tr>
          <tr v-for="(entity,index) in entitiesWithRanking" :key="`info-${index}`">
            <td>{{entity.name}}</td>
            <td>
              <span class="rank">{{commaDelimited(entity.ranking.nationwide.rank)}}</span>
              <span>/</span>
              <span>{{commaDelimited(entity.ranking.nationwide.rankedOutOf)}}</span>
              <span class="percentile">{{entity.ranking.nationwide.percentile}}%</span>
            </td>
            <td>
              <span class="rank">{{commaDelimited(entity.ranking.state.rank)}}</span>
              <span>/</span>
              <span>{{commaDelimited(entity.ranking.state.rankedOutOf)}}</span>
              <span class="percentile">{{entity.ranking.state.percentile}}%</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <input v-if="loaded" value="Fetch Rankings" type="button" class="btn btn-secondary mt-2" @click="refetchRanking">
  </div>
</template>

<script>
import axios from "axios"
import Spinner from "./Spinner.vue"

export default {
  components: {
    Spinner
  },
  data: function() {
    return {
      loaded: true,
      entities: []
    }
  },
  mounted() {
    this.fetchRankings()
  },
  computed: {
    entitiesWithRanking() {
      let filtered = this.entities
        .filter((entity) => entity["ranking"] != null)
        .filter((entity) => entity["ranking"]["rankError"] == undefined)
            
      return filtered
    }
  },
  methods: {
    commaDelimited(value) {
      if (value == undefined || value == null) {
        return value;
      }

      return value.toLocaleString();
    },
    refetchRanking() {
      this.loaded = false
      axios.patch(`/properties/${this.$route.params.id}/update_placer.json`)
        .then(response => {
          if (Array.isArray(response.data.entities)) {
            this.entities = response.data.entities
            this.loaded = true
          }
        })
    },
    fetchRankings() {
      axios.get(`/properties/${this.$route.params.id}/placer_ranking.json`)
        .then(response => {
          if (Array.isArray(response.data.entities)) {
            this.entities = response.data.entities
            this.loaded = true
          }
      })
      .catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style>
.tenants {
  display: flex; 
  flex-wrap: wrap;
}

.tenant-item {
  display: flex;
  flex: 40%;
}

.rankings {
  display: flex; 
  flex-wrap: wrap
}

.ranking-item {
  flex: 30%;
}

.location-title {
  font-size: 16pt;
  margin-bottom: 20px;
}

.rank {
  font-size: 20pt;
}

.percentile {
  float: right;
  margin-top: 11px;
  margin-right: 100px;
}
</style>