<template>
  <div id="app">
    <Navbar />
    
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';

export default {
  props: [
    "statuses",
    "currentUser"
  ],
  components: { 
    Navbar
  },
  mounted() {
    this.$store.commit('underwritingStatuses', this.statuses);
    this.$store.commit('setCurrentUser', this.currentUser)
    
    this.$store.dispatch('refinance');
    this.$store.dispatch('search');
  }
}
</script>

