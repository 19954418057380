<template>
  <div>
    <div v-for="log in allLogs" v-bind:key="log.id" class="log-item">
      <template v-if="log.contact_date">
        <div class="log-icon"></div>
        <div class="log-body">
          <p class="mb-0">{{ log.person_name }} <span>&#183;</span> {{ log.person_phone }} <span>&#183;</span> {{ log.person_email }}</p>
          <span>Outreach Type: {{ log.outreach_type }}</span>
          <p>{{ log.notes }}</p>
        </div>
        <div class="log-date justify-content-between">
          <div>{{formatDateString(log.contact_date)}}</div>
    
          <div>
            <a v-b-modal="`modal-edit-${log.id}`">
              <b-icon icon="pencil"></b-icon>
            </a>
      
            <b-modal :id="`modal-edit-${log.id}`" title="Edit Outreach Log" @ok="updateLog(log.id)" @close="unsetEditedLog" @cancel="unsetEditedLog">
              <p class="my-4">
                <OutreachForm :log="log" @logChange="logChanged"/>
              </p>
            </b-modal>

            <a v-b-modal="`modal-delete-${log.id}`">
              <b-icon icon="trash"></b-icon>
            </a>
            <b-modal :id="`modal-delete-${log.id}`" title="Delete Outreach Log"  @ok="deleteLog(log)">
              <p class="my-4">Are you sure want to delete this log?</p>
            </b-modal>
          </div>
        </div>
      </template>
      <template v-else-if="log.contact_id == contact.close_io_contact_id">
        <div class="log-icon"></div>
        <div class="log-body">
          <p class="mb-0">CLOSE.IO Activity</p>
          <span>Outreach Type: {{ log._type }}</span>
          <p>{{ log.note }}</p>
          
          <template v-if="log._type == 'Email'">
            <p class="mb-0">Sender: {{ log.sender }}</p>
            <p class="mb-0">Recipient: {{ log.to[0] }}</p>
            <p class="mt-2">{{ log.body_preview }}</p>
          </template>
        </div>
        <div class="log-date justify-content-between">
          <div>{{formatDateString(log.date_created)}}</div>
        </div>
      </template>
    </div>
    <div class="log-comment p-3 d-flex justify-content-between">
      <span>Property Uploaded to DealFinder!</span>
    </div>
  </div>
</template>

<script>
import axios from "axios"

import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

import OutreachForm from './OutreachForm.vue'

export default {
  components: {
    OutreachForm,
  },
  props: [
    "outreach_logs",
    "activities"
  ],
  data() {
    return {
      contact: {}
    }
  },
  mounted() {
    this.fetchDefaultContact()
  },
  methods: {
    fetchDefaultContact() {
      const propertyId = this.$route.params.id;

      axios.get(`/properties/${propertyId}/contacts/last.json`)
      .then(response => {
        this.contact = response.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    deleteLog(log) {
      const propertyId = this.$route.params.id;

      axios.delete(`/properties/${propertyId}/outreach_logs/${log.id}.json`)
        .then(response => {
          let index = this.outreach_logs.findIndex(e => e.id == log.id)
          this.outreach_logs.splice(index, 1)
        })
    },
    updateLog: function(logId) {
      const notyf = new Notyf({
        position: {
          x: 'center',
          y: 'top',
        }
      });

      const propertyId = this.$route.params.id;

      axios.patch(`/properties/${propertyId}/outreach_logs/${logId}.json`, { outreach_log: this.edited_log })
      .then(response => {
        notyf.success('Action Log updated!')
        this.fetchLogs()
      })
      .catch(error => {
        notyf.error('Oops, notes could not be updated')
      })
    },
    formatDateString(dateString) {
      var date = new Date(dateString)
      var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }

      return `${date.toLocaleDateString("en-US", options)} ${date.toLocaleTimeString()}`
    },
    unsetEditedLog(){
      this.edited_log = null
    },
    logChanged(log) {
      this.edited_log = log;
    },
    newLogChanged(log) {
      this.outreach_log = log
    }
  },
  computed: { 
    allLogs() {
      let activities = this.activities.filter((activity) => {
        return activity.contact_id == this.contact.close_io_contact_id || activity.contact_id == null
      })
      
      let allLogs = this.outreach_logs.concat(activities)
    
      allLogs.sort((a, b) => {
        const aDate = new Date(a.contact_date || a.date_created);
        const bDate = new Date(b.contact_date || b.date_created);

        return bDate - aDate;
      })

      return allLogs
    }
  },
}
</script>

<style>
.log-item {
  position: relative;
  padding: 8px 0;
  margin-left: 16px;
  display: flex;
}

.log-item::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 2px;
  content: "";
  background-color: #EEE;
}

.log-icon {
  margin-top: 5px;
  color: #DDD;
  z-index: 1;
  margin-right: 8px;
  margin-left: -3.85%;
  flex-shrink: 0;
  flex: 3%;
  background-image: url('../../svg/dot.svg');
  background-repeat: no-repeat;
  background-position: top center;
}

.log-body {
  flex: 45%;
}

.log-date {
  text-align: right;
  flex: 40%;
}

.log-comment {
  position: relative;
  color: #000;
  background-color: #FFF;
  border: 1px solid #EEE;
  border-radius: 6px;
}
</style>