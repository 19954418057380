<template>
  <div class="mt-2">
    <div class="container-fluid" :class="{ 'shifted-left': !this.$store.state.showSidebar }">
      <div class="row">
        
        <div class="col-2">
          <slot name="search"></slot>
        </div>
        
        <div class="col-10">
          <slot name="loans"></slot>
          
          <Spinner v-if="isFetching || isFetchingInfiniteScroll" class="mt-5" />
        </div>
        
      </div>
    </div>
    
    <router-view name="property"></router-view>
  </div>
</template>

<script>
import Spinner from "./components/Spinner.vue"

import { mapFields } from 'vuex-map-fields'

export default {
  components: { 
    Spinner
  },
  computed: {
    ...mapFields([
      'isFetching',
      'isFetchingInfiniteScroll',
    ])
  }
}
</script>

<style scoped>
  .container-fluid {
    transform: translateX(0px);
    transition: all .2s ease;
  }
  
  .shifted-left {
    transform: translateX(-16.6666666667%);
  }
</style>
