<template>
  <ul>
    <li v-for="pill in pills" v-bind:key="pill.key" class="tag-element">
      <span v-if="isString(pill.value) || isBoolean(pill.value)" class="pill-label">
        {{ titleFromKey(pill.key) }}: {{ valueTitle(pill.key, pill.value) }}
        <a @click="deleteKey(pill)" class="close-tag"></a>
      </span>

      <span v-else class="pill-label">
        {{ titleFromKey(pill.key) }}:
        
        <span v-for="(val,index) in pill.value" v-bind:key="index" class="pill-item">
          {{ valueTitle(pill.key, val) }}
          <a @click="deleteKey(pill, val)" class="close-tag"></a>
        </span>
      </span>
    </li>
  </ul>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import states from '../json/states.json'
import {propertyTypes} from '../store'
const types = propertyTypes

export default{
  data(){
    return {
    }
  },
  computed: {
    ...mapFields([
      'pills'
    ])
  },
  methods: {
    isString(value) {
      return typeof(value) == 'string';
    },
    isBoolean(value) {
      return typeof(value) == 'boolean';
    },
    titleFromKey(key) {
      return key.replaceAll("_", " ")
    },
    valueTitle(key, value) {
      if (key == "property_type") {
        return types.find((x) => x[0] == value)[1]
      } else if (key == "state") {
        return states[value]
      } else if (typeof(value) == 'boolean') {
        return (value)? 'True' : 'False'
      }
      return value.replaceAll("_", " ")
    },
    deleteKey(pill, val) {
      this.$store.commit('removePill', { key: pill['key'], value: val })
      this.$store.dispatch('search')
    }
  }
}
</script>

<style scoped>
  .tag-element {
    margin: 0 2px;
    padding: 2px 22px 2px 10px;
    display: inline-block;
    border: 1px solid #D0D4D8;
    border-radius: 72px;
    color: #626D7B;
    line-height: 22px;
    font-size: 12px;
    position: relative;
    text-transform: capitalize;
  }

  .tag-element .close-tag {
    top: 4px;
    right: -1px;
    width: 15px;
    height: 15px;
    display: inline-block;
    position: relative;
    background-image: url('../svg/close-tag.svg');
    background-repeat: no-repeat;
    cursor: pointer;
  }

</style>