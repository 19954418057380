<template>
  <td ref="highlight">{{ property.loan.servicer_commentary }}</td>
</template>

<script>
import Mark from 'mark.js'
import { mapFields } from 'vuex-map-fields'

export default {
  props: ['property'],
  computed: {
    ...mapFields([
      'search.commentary'
    ])
  },
  methods: {
    highlight() {
      var instance = new Mark(this.$refs.highlight);
      
      instance.mark(this.commentary.split(', ')[0], { 
        separateWordSearch: false,
        wildcards: "enabled",
        synonyms: {
          "month to month": "month-to-month|MTM|mtm",
          "deed in lieu": "deed-in-lieu|DIL",
          "mezzanine": "mezz",
        }
      });
    }
  },
  mounted() {
    this.highlight()
  }
}
</script>

<style>
  mark {
    background: yellow !important;
    color: black;
  }
</style>