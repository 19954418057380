<template>
  <div>
    <button v-if="showSaveSearchButton" class="btn btn-secondary save-search-button" v-b-modal="`modal-save-search`">
      <b-icon class="" icon="plus"/>
      Save Search
    </button>
    <b-modal id="modal-save-search" size="lg" title="Save Search" @ok="create">      
      <NewSearch :name="newSearch.name" @change="newSearchChange"></NewSearch>
    </b-modal>
    <button v-if="savedSearches.length > 0" v-b-modal="`modal-show-saved`" class="btn btn-secondary saved-search-button">Saved searches</button>
    <b-modal id="modal-show-saved" title="Saved Searches" :hide-footer="true" @show="fetchSaved">
      <table class="table">
        <thead>
          <tr>
            <th>Search Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="saved in savedSearches" :key="saved.id">
            <td>
              <a class="saved-search-link" @click="loadSearch(saved)">{{ saved.name }}</a>
            </td>
            <td>
              <b-icon class="delete-saved-search" icon="trash" v-b-modal="`modal-delete-search-${saved.id}`"/>
              <b-modal :id="`modal-delete-search-${saved.id}`" title="Delete Saved Search" @ok="deleteSearch(saved)">
                Are you sure, want to delete this saved search?
              </b-modal>
            </td>
          </tr>
        </tbody>
      </table>
    </b-modal>
    <button @click="clearSearch" class="btn btn-secondary clear-search-button">Clear search</button>
  </div>
</template>

<script>
  import axios from "axios"
  import { Notyf } from 'notyf'
  import 'notyf/notyf.min.css'
  import NewSearch from './NewSearch.vue'
  import { stringify } from "querystring"
  import { search } from '../../json/search';

  export default {
    components: {
      NewSearch
    },
    data() {
      return {
        newSearch: {
          name: ''
        },
        savedSearches: []
      }
    },
    mounted() {
      this.fetchSaved()
    },
    computed: {
      showSaveSearchButton() {
        return this.$store.state.showSaveSearchButton
      }
    },

    methods: {
      create() {
        const notyf = new Notyf({
          position: {
            x: 'center',
            y: 'top',
          }
        })

        let params = {
          name: this.newSearch.name,
          keywords: JSON.stringify(this.$store.state.search)
        }
        
        axios.post(`/searches.json`, { search: params })
          .then(response => {
            notyf.success('Search saved!')
            
            this.fetchSaved()
            this.newSearch.name = ''
          })
          .catch((error) => {
            if (error.response.status == 422) {
              notyf.error(error.response.data.message)  
            } else {
              notyf.error('Oops, search could not be saved')
            }
          })
      },

      fetchSaved() {
        axios.get(`/searches.json`)
          .then(response => {
            this.savedSearches = response.data.searches
          })
      },

      deleteSearch(search) {
        const notyf = new Notyf({
          position: {
            x: 'center',
            y: 'top',
          }
        })

        axios.delete(`/searches/${search.id}`)
          .then(response => {
            this.fetchSaved()
            notyf.success('Search deleted!')
          })
      },

      loadSearch(savedSearch) {
        this.$bvModal.hide('modal-show-saved')
        this.$store.dispatch("applySearch", savedSearch.keywords)
      },

      clearSearch() {
        this.$store.dispatch("applySearch", search)
      },

      newSearchChange(data) {
        this.newSearch = data
      }
    }

  }
</script>

<style>
  .saved-search-link:hover, .delete-saved-search:hover {
    cursor: pointer;
  }
  .save-search-button, .saved-search-button,  .clear-search-button {
    margin-left: 0.5rem;
  }
</style>