import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import { getField, updateField } from 'vuex-map-fields'
import  stateList from '../json/states.json'
import { search } from '../json/search.js';

import { filterParams } from '../utils/filterParams';

let states = stateList
Vue.use(Vuex)

let searchCancelToken = null
let searchCancelTokenProperties = null
let searchCancelTokenRefinance  = null

export const propertyTypes = [
  ['', 'All'],
  ['RT-', 'Retail'],
  ['OF-', 'Office'],
  ['LO', 'Hotel'],
  ['MF-', 'Multifamily'],
  ['SS', 'Self Storage'],
  ['IN', 'Industrial'],
  ['MU', 'Mixed Use']
]

export default new Vuex.Store({
  state: {
    showSaveSearchButton: false,
    arrowKeyDisabled: false,
    current_user: {},
    showSidebar: true,
    isFetching: true,
    isFetchingInfiniteScroll: false,
    selectedPropertyId: -1,
    reportingData: {
      dailyLogs: [],
      monthlyLogs: []
    },
    refinanceData: {
      properties: [],
      pagy: {
        next: 1
      }
    },
    dashboardData: {
      properties: [],
      pagy: {
        next: 1
      }
    },
    propertyData: {
      properties: [],
      pagy: {
        next: 1
      }
    },
    refinance: {
      property_type: '',
      noi_minimum: 1,
      status: [],
      sort: 'properties.name',
      direction: 'asc',
      maturity_date: '2024-12-31',
      debt_yield_shortfall: {
        industrial: 11,
        multifamily: 9,
        retail: 12,
        office: 13.5,
        hotel: 18.5,
        self_storage: 9.5,
        mixed_use: 12,
        default: 8
      }
    },
    dashboard: {
      sort: 'properties.name',
      direction: 'asc',
      status: ['Call Borrower']
    },
    search: search,
    pills: [{ key: 'newer_commentary_date', value: true }], // [{key: "name", value: "test"}, {key: "multi", value: ["aaaa", "bbbb"]}]
    underwritingStatuses: [],
    available_trepp_subtypes: []
  },
  getters: {
    getField,
    adjacentPropertyId: (state) => (direction) => {
      let index = state.propertyData.properties.findIndex((prop) => prop.id == state.selectedPropertyId)

      if (index != -1) {
        let nextProperty = state.propertyData.properties[index + direction]

        if (nextProperty) {
          return nextProperty.id
        }
      }

      return null
    },
    allowedPropertyTypeFilters(state) {
      // if user not set for first load, just return all
      if (state.current_user.permitted_property_type_filter == undefined) {
        return propertyTypes
      }

      let allowed = state.current_user.permitted_property_type_filter
      
      // skip filtering if contains 'all'
      if (allowed.includes("all")) {
        return propertyTypes
      }
      
      // use filtering
      let types = []

      // add all option
      if (allowed.length > 1) {
        types.push(propertyTypes[0])
      } else {
        state.search.property_type = allowed[0]
      }

      for (let i = 1; i < propertyTypes.length; i++) {
        if (allowed.includes(propertyTypes[i][0])) {
          types.push(propertyTypes[i])
        }
      }
      
      return types
    }
  },
  mutations: {
    updateField,
    loadSearch(state, searchParams) {
      state.search = searchParams
    },
    showSaveSearchButton(state, show) {
      state.showSaveSearchButton = show
    },
    setAvailableTreppSubtypes(state, subtypes) {
      state.available_trepp_subtypes = subtypes
    },
    setArrowKeyDisabled(state, status) {
      state.arrowKeyDisabled = status
    },
    setCurrentUser(state, user) {
      state.current_user = user
    },

    setSelectedPropertyId(state, propertyId) {
      state.selectedPropertyId = propertyId
    },
    
    underwritingStatuses(state, statuses) {
      state.underwritingStatuses = statuses
    },
    
    showSidebar(state, showSidebar) {
      state.showSidebar = showSidebar
    },
    
    isFetching(state, isFetching) {
      state.isFetching = isFetching
    },
    
    isFetchingInfiniteScroll(state, isFetchingInfiniteScroll) {
      state.isFetchingInfiniteScroll = isFetchingInfiniteScroll
    },

    addReportingData(state, data) {
      state.reportingData.dailyLogs = data.daily.outreach_logs
      state.reportingData.monthlyLogs = data.monthly.outreach_logs
    },
    
    addDashboardData(state, data) {
      state.dashboardData.properties.push(...data.properties)
      state.dashboardData.pagy = data.pagy
    },
    
    addPropertyData(state, data) {
      state.propertyData.properties.push(...data.properties)
      state.propertyData.pagy = data.pagy
    },
    
    addRefinanceData(state, data) {
      state.refinanceData.properties.push(...data.properties)
      state.refinanceData.pagy = data.pagy
    },
    
    clearCommentary(state) {
      state.search.commentary = ''
    },
    
    clearProperties(state) {
      state.propertyData.properties = []
      state.propertyData.pagy = { next: 1 }
    },

    clearDashboard(state) {
      state.dashboardData.properties = []
      state.dashboardData.pagy = { next: 1 }
    },
    
    clearRefinance(state) {
      state.refinanceData.properties = []
      state.refinanceData.pagy = { next: 1 }
    },
    
    applySort(state, payload) {
      console.log(payload.form);
      
      state[payload.form].sort = payload.sort
      state[payload.form].direction = payload.direction
    },

    clearStates(state) {
      state.search.state = []
    },

    fillAllStates(state) {
      state.search.state = Object.keys(states)
    },
    
    fillAllStatuses(state) {
      state.search.status = state.underwritingStatuses
      state.refinance.status = state.underwritingStatuses
    },
    
    clearStatuses(state) {
      state.search.status = []
      state.refinance.status = []
    },

    updateStates(state, newStates) {
      state.search.state = newStates
    },

    addPill(state, data) {
      let index = state.pills.findIndex((x) => x["key"] == data["key"])
      
      // If search key is found in pills
      if (index != -1) {
        // If blank value
        if (data["value"] == "" || data["value"] == 0) {
          // Remove from pills aray
          state.pills.splice(index, 1)
        } else {
          // Add value to pills array
          state.pills[index]["value"] = data["value"]
        }
      } else {
        // If search key is not in the pills array, add it
        if (data["value"] != "") {
          state.pills.push({ key: data["key"], value: data["value"] })
        }
      }
    },

    removePill(state, data){
      let keyName  = data["key"]
      let keyValue = data["value"]

      if (keyName == "total_balance_min") {
        state.search.total_balance.min = ""
      } else if (keyName == "total_balance_max") {
        state.search.total_balance.max = ""
      } else if (keyName == "occupancy_min") {
        state.search.occupancy.min = ""
      } else if (keyName == "occupancy_max") {
        state.search.occupancy.max = ""
      } else if (keyName == "sq_footage_min") {
        state.search.sq_footage.min = ""
      } else if (keyName == "sq_footage_max") {
        state.search.sq_footage.max = ""
      } else if (keyName == "box_sq_footage_min") {
        state.search.box_sq_footage.min = ""
      } else if (keyName == "box_sq_footage_max") {
        state.search.box_sq_footage.max = ""
      } else if (keyName == "dscr_min") {
        state.search.dscr.min = ""
      } else if (keyName == "dscr_max") {
        state.search.dscr.max = ""
      } else if (keyName == "loan_rate_min") {
        state.search.loan_rate.min = ""
      } else if (keyName == "loan_rate_max") {
        state.search.loan_rate.max = ""
      } else if (keyName == "number_of_units_min") {
        state.search.number_of_units.min = ""
      } else if (keyName == "number_of_units_max") {
        state.search.number_of_units.max = ""
      } else if (keyName == "year_built_min") {
        state.search.year_built.min = ""
      } else if (keyName == "year_built_max") {
        state.search.year_built.max = ""
      } else if (keyName == "debt_yield_noi_min") {
        state.search.debt_yield_noi.min = ""
      } else if (keyName == "debt_yield_noi_max") {
        state.search.debt_yield_noi.max = ""
      } else if (keyValue) {
        let index = state.search[keyName].findIndex((x) => x == keyValue)
        
        state.search[keyName].splice(index, 1)
      } else {
        state.search[keyName] = ''
      }
    }
  },
  actions: {
    async reporting({ commit }) {
      axios.get('/outreach_logs/summary.json', {
        params: {}
      })
      .then((response)=> {
        commit("addReportingData", response.data)
      })
      .catch((error)=> {
        console.log(error)
      })
    },
    async dashboard({ commit }, infiniteScroll = false) {
      if (!infiniteScroll) {
        commit('clearDashboard')
        commit('isFetching', true)
      } else {
        commit('isFetchingInfiniteScroll', true)
      }
      if (searchCancelToken != null) {
        searchCancelToken.cancel()
      }
      
      searchCancelToken = axios.CancelToken.source()

      const params = {
        ...this.state.dashboard,
        items: 50,
        page: this.state.dashboardData.pagy.next
      }
      
      axios.get('/properties.json',  {
        params: params,
        cancelToken: searchCancelToken.token
      })
      .then(function(response) {
        commit('isFetching', false)
        commit('isFetchingInfiniteScroll', false)
        commit('addDashboardData', response.data)
      })
      .catch(function(error) {
        console.log(error);
      })
    },
    async applySearch({ commit, dispatch }, searchParams) {
      commit("loadSearch", searchParams)
      dispatch("search")
    },
    async search({ commit }, infiniteScroll = false) {
      if (Object.keys(this.state.current_user).length === 0) return;
      
      if (!infiniteScroll) {
        commit('clearProperties')
        commit('isFetching', true)
      } else {
        commit('isFetchingInfiniteScroll', true)
      }
      
      if (searchCancelTokenProperties != null) {
        searchCancelTokenProperties.cancel()
      }
      
      searchCancelTokenProperties = axios.CancelToken.source()

      const params = filterParams({
        ...this.state.search,
        page: this.state.propertyData.pagy.next
      })
            
      axios.get('/properties.json',  {
        params: params,
        cancelToken: searchCancelTokenProperties.token
      })
      .then(function(response) {
        if (!infiniteScroll) {
          commit('clearProperties')
        }
        
        commit('isFetching', false)
        commit('isFetchingInfiniteScroll', false)
        commit('addPropertyData', response.data)
        commit("showSaveSearchButton", true)
      })
      .catch(function(error) {
        console.log(error);
        commit('isFetching', false)
        commit('isFetchingInfiniteScroll', false)
      })
    },
    async refinance({ commit }, infiniteScroll = false) {
      if (!infiniteScroll) {
        commit('clearRefinance')
        commit('isFetching', true)
      } else {
        commit('isFetchingInfiniteScroll', true)
      }
      
      if (searchCancelTokenRefinance != null) {
        searchCancelTokenRefinance.cancel()
      }
      
      searchCancelTokenRefinance = axios.CancelToken.source()

      const params = {
        ...this.state.refinance,
        page: this.state.refinanceData.pagy.next
      }
      
      axios.get('/properties.json',  {
        params: params,
        cancelToken: searchCancelTokenRefinance.token
      })
      .then(function(response) {
        if (!infiniteScroll) {
          commit('clearRefinance')
        }
        
        commit('isFetching', false)
        commit('isFetchingInfiniteScroll', false)
        commit('addRefinanceData', response.data)
      })
      .catch(function(error) {
        console.log(error);
      })
    },
    async loadGlobalUnderwritingStatuses({commit}) {
      axios.get(`/underwriting_statuses`)
        .then(responseIndex => {
          let statuses = responseIndex.data.underwriting_statuses.map(s => s.name)
          
          commit("underwritingStatuses", statuses)
        })
    },
    async loadTreppSubtypes({commit}) {
      if (this.state.search.property_type) {
        axios.get(`/property_types/${this.state.search.property_type}/trepp_subtypes`)
        .then(response => {
          commit("setAvailableTreppSubtypes", response.data.subtypes)
        })
      }
    }
  }
})