<template>
  <div class="mt-2">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <h4>DK Dashboard - {{ this.pagy.count }} properties marked Call Borrower</h4>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th class="sm-width-column"><SortableTableHeader title="Status" column="status" /></th>
                <th>Property Name</th>
                <th>Property Type</th>
                <th>City</th>
                <th><SortableTableHeader title="State" column="state" form="dashboard" /></th>
                <th><SortableTableHeader title="SF/Units" :column="squareFeetUnitColumn" form="dashboard" /></th>
                <th><SortableTableHeader title="Total Balance" column="total_balance" form="dashboard" /></th>
                <th><SortableTableHeader title="Last Outreach" column="last_contact_date" form="dashboard" /></th>
                <th><SortableTableHeader title="Callback Reminder" column="callback_reminder_at" form="dashboard" /></th>
                <th><SortableTableHeader title="Added to Call Borrower" column="underwriting_statuses.created_at" form="dashboard" /></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(property,index) in this.properties" :key="`dash-prop-${index}`">
                <TagInput :initialStatuses="underwritingStatusText(property.underwriting_statuses)" :property="property"></TagInput>
                <td><router-link :to="`/dashboard/property/${property.id}`">{{ property.name }}</router-link></td>
                <td>{{ property.trepp_subtype }}</td>
                <td>{{ property.city }}</td>
                <td>{{ property.state }}</td>
                <td>{{ commaDelimited(units(property)) }}</td>
                <td>${{ commaDelimited(property.total_balance) }}</td>
                <td>{{ outreach_date(property) }}</td>
                <td>{{ date_from_field(property.callback_reminder_at) }}</td>
                <td>{{ underwriting_status_date(property) }}</td>
              </tr>
            </tbody>
          </table>
          <Spinner v-if="isFetching || isFetchingInfiniteScroll" class="mt-5" />
        </div>
      </div>
    </div>
    
    <router-view name="property"></router-view>
  </div>
</template>

<script>

import axios from 'axios';
import { debounce } from 'lodash'
import SortableTableHeader from '../components/SortableTableHeader.vue';
import TagInput from '../components/TagInput.vue';
import Spinner from "../components/Spinner.vue"

import { mapFields } from 'vuex-map-fields'

export default {
  data() {
    return {
    }
  },

  created(){
    this.debouncedSearch = _.debounce(this.search, 200)
  },

  mounted() {
    this.debouncedSearch();
    
    window.onscroll = () => {
      if (this.pagy.page == this.pagy.last) {
         return;
      }
      let navbarHeight = 64.0
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight + navbarHeight >= document.documentElement.offsetHeight;

      if (bottomOfWindow && !this.isFetchingInfiniteScroll) {
        this.$store.dispatch('dashboard', true)
      }
    }
  },

  components: {
    SortableTableHeader,
    TagInput,
    Spinner
  },

  computed: {
    ...mapFields([
      'dashboardData.properties',
      'dashboardData.pagy',
      'isFetching',
      'isFetchingInfiniteScroll',
    ]),
    
    squareFeetUnitColumn: function() {
      if (this.property_type == 'LO-' || this.property_type == 'MF-') {
        return 'number_of_units'
      } else {
        return 'square_feet'
      }
    }
  },
  methods: {
    search() {
      this.$store.dispatch('dashboard')
    },
    underwritingStatusText(statuses) {
      if (statuses.length > 0) {
        return statuses.map(s => s.name).sort()
      }
      
      return ['Unlooked']
    },
    underwriting_status_date(property) {
      let status = property.underwriting_statuses.filter((status) => status.name == "Call Borrower")
            
      var date = new Date(status[0].created_at)
    
      return date.toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})
    },
    date_from_field(field) {
      if (field == null) {
        return "Never"
      }
      
      var date = new Date(field)
    
      return date.toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})
    },
    outreach_date(property) {
      if (property.last_contact_date == null) {
        return "Never"
      }
      
      var date = new Date(property.last_contact_date)
    
      return date.toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})
    },
    status(property) {
      if (property.loan.special_service_status == 'Yes') {
        return "Special Serviced"
      }
    
      if (property.loan.watchlist_status == 'Yes') {
        return "Watchlist"
      } 
    },
    commaDelimited(value) {
      if (value == undefined || value == null) {
        return value;
      }
    
      return value.toLocaleString();
    },
    units(property) {
      if (property.number_of_units) {
        return property.number_of_units
      } else {
        return property.square_feet
      }
    },
  }
}
</script>

<style scoped>
.sm-width-column {
  width: 165px;
}
</style>
