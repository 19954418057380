/* eslint no-console: 0 */

import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue'
import axios from 'axios'

import store from '../app/store'
import App from '../app/App.vue'
import router from '../app/router'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Import fontawesome
import '@fortawesome/fontawesome-free/scss/fontawesome';
import '@fortawesome/fontawesome-free/scss/solid';
import '@fortawesome/fontawesome-free/scss/regular';
import '@fortawesome/fontawesome-free/scss/brands';
import '@fortawesome/fontawesome-free/scss/v4-shims';
import "@fortawesome/fontawesome-free/js/all";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(TurbolinksAdapter)

document.addEventListener('turbolinks:load', () => {
  let csrfMeta = document.querySelector('meta[name="csrf-token"]')

  // need check because on test environment meta tag doesn't exist
  axios.defaults.headers.common['X-CSRF-Token'] = (csrfMeta == null)? '' : csrfMeta.getAttribute('content')
  
  store.dispatch('search');
  
  const app = new Vue({
    el: '[data-behavior="vue"]',
    router: router,
    store: store,
    components: { App }
  })  
})