<template lang="html">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#" @click="clearSelectedPropertyId">FFDealFinder</a>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link to="/" class="nav-link">Properties</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/refinance" class="nav-link">Refinance</router-link>
          </li>
        </ul>
      </div>

      <div>
        <div class="d-flex">
          <ExportCsvButton/>
          <SavedSearch/>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import ExportCsvButton from './ExportCsvButton.vue'
import ExportJsonButton from './ExportJsonButton.vue'
import SavedSearch from './search/SavedSearch.vue'

export default {
  components: {
    ExportCsvButton,
    ExportJsonButton,
    SavedSearch
  },
  methods: {
    clearSelectedPropertyId: function(){
      this.$store.commit("setSelectedPropertyId", -1)
    }
  }
}
</script>
