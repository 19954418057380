<template>
  <div>
    <form @submit.prevent="execute" class="form-inline">
      <div class="input-group">
        <input type="text" v-model="query" class="mb-2 form-control" />
        <button type="submit" class="btn btn-primary mb-2">Search Google Images</button>
      </div>
    </form>
      
    <div class="row">
      <div class="col-4" v-for="image in images">
        <div class="card">
          <img class="card-img-top w-100" :src="image.link">
          <div class="card-body">
            <a target="_blank" :href="image.image.contextLink">{{ image.title }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['property'],
  data() {
    return {
      images: [],
      query: `${this.property.name} ${this.property.city} ${this.property.state}`,
      searchEngineId: "b64d3c739ac231673",
      apiKey: "AIzaSyARgDQcHIOCnLBtWd3kCdwtytxGTBmQ5G4"
    }
  },
  mounted() {
    let vm = this;
    
    gapi.load("client", () => {
      vm.loadClient()
    });    
  },
  methods: {
    loadClient() {
      let vm = this;
      
      gapi.client.setApiKey(this.apiKey);
    
      return gapi.client.load("https://content.googleapis.com/discovery/v1/apis/customsearch/v1/rest")
          .then(function() { 
            vm.execute()
          },
          function(err) { 
            console.error("Error loading GAPI client for API", err); 
          });
    },
    execute() {
      let vm = this;
      
      return gapi.client.search.cse.list({
        "q": this.query,
        "cx": this.searchEngineId,
        "searchType": "image",
        "imgSize": "large"
      })
      .then(function(response) {
        vm.images = response.result.items
      },
      function(err) { 
        console.error("Execute error", err)
      });
    }    
  }
}
</script>

<style lang="scss" scoped>
img {
  height: 400px;
  object-fit: cover;
}
</style>