<template>
  <td @click="showInput">  
    <div class="text-box">
      <div class="tags-list">
        <span class="tags-list-item" v-for="(status, index) in statuses" :key="index">
          <span class="tags-text">{{ status }}</span>
          <a @click="remove(index)" class="close-tag"></a>
        </span>
        <input
          type="text"
          v-show="show"
          class="tags-input"
          ref="tagInput"
          v-model="text"
          @keyup.enter="submit"
          @keyup.188="submit"
          @blur="hideInput"
        />
      </div>
    </div>
  </td>
</template>

<script>
import axios from 'axios'

import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

export default {
  name: "TagInput",
  props: {
    property: {},
    initialStatuses: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      statuses: this.initialStatuses,
      text: '',
      show: false
    }
  },
  methods: {
    remove(index) {
      this.statuses.splice(index, 1);
      
      this.updateUnderwritingStatus()
    },
    submit(e) {
      this.show = false;
      
      if (!this.text) return;
      
      if (this.text == ",") {
        this.text = "";
        return;
      }
      
      this.text = this.text.replace(",", "");
      
      const isNotExist = this.statuses.filter(status => {
        return status != this.text;
      });
      
      if (isNotExist) {
        this.statuses.push(this.text.replace(",", ""));
        this.text = "";
      }
      
      this.updateUnderwritingStatus()
    },
    focusOnTagInput() {
      this.$nextTick(() => {
        const tagInputRef = this.$refs.tagInput;
        tagInputRef.focus();
      }); 
    },
    showInput() {
      this.show = true;
      this.focusOnTagInput();
    },
    hideInput() {
      this.show = false;
    },
    updateUnderwritingStatus() {
      if (this.statuses.length == 0) {
        return false
      }
      
      const notyf = new Notyf({
        position: {
          x: 'center',
          y: 'top',
        }
      });
      
      axios.post(`/properties/${this.property.id}/underwriting_statuses`, {
        statuses: this.statuses
      })
      .then(response => {
        this.$store.dispatch("loadGlobalUnderwritingStatuses")
        
        notyf.success('Status updated!');
      })
      .catch(error => {
        notyf.error('Oops, status could not be updated!');
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.text-box {
  -webkit-appearance: none;
  max-width: 200px;
}

.tags-list-item {
  background-color: #ecf5ff;
  display: inline-block;
  height: 24px;
  padding: 0 8px;
  line-height: 22px;
  font-size: 14px;
  color: #409eff;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  margin: 2px 0 2px 6px;
  cursor: pointer;
}

.tags-text {
  color: #000;
}

.tags-input {
  border: 0;
  border: none;
  outline: none;
  padding: 0;
  margin-left: 15px;
  color: #666;
  font-size: 14px;
  appearance: none;
  height: 28px;
  background-color: transparent;
}

.tags-input:focus {
  outline: none;
}

.close-tag {
  top: 4px;
  right: -1px;
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  background-image: url('../svg/close-tag.svg');
  background-repeat: no-repeat;
  cursor: pointer;
}
</style>