<template>
  <div>
    <div class="mb-2 text-end">
      <button v-b-modal="`request-file`" class="btn btn-secondary">
        Request File
      </button>
      <b-modal id="request-file" title="Request File via Email" :hide-footer="true">
        <EmailForm :property="property" :defaultSender="user.email" @formSubmit="sendEmail"/>
      </b-modal>
    </div>
    
    <div class="upload-zone" @drop="fileDropped($event)" @dragover.prevent @dragenter.prevent>
      File upload
      <input type="file" multiple @change="fileChanged($event)" ref="input_file">
    </div>
    
    <div v-if="uploads.length > 0">
      <div v-for="upload in uploads.slice().reverse()" :key="upload.uid" class="progress-bar-container">
        <div class="file-info">
          {{upload.name}}
          <span v-if="upload.progress == 0"> Preparing for upload ...</span>
          <span v-else> {{progressPercentage(upload)}} %</span>
        </div>
        <div :style="`width: ${progressPercentage(upload)}%;`" class="progress-bar">&nbsp;</div>
      </div>
    </div>
  
    <div class="mt-3" v-if="property_files.length > 0">
      <h3>Files</h3>
      <table class="table">
        <thead>
          <th>Filename</th>
          <th></th>
        </thead>
        <tbody>
          <tr v-for="property_file in property_files" :key="property_file.id">
            <td>
              <a :href="property_file.file.url" target="_blank">
                {{property_file.file.filename}}
              </a>
            </td>
            <td>
              <a v-b-modal="`modal-${property_file.id}`">
                <b-icon icon="trash"></b-icon>
              </a>
              <b-modal :id="`modal-${property_file.id}`" title="Delete File" @ok="deletePropertyFile(property_file)">
                <p class="my-4">Are you sure want to delete this file?</p>
              </b-modal>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { DirectUpload } from "@rails/activestorage"
import { v4 as uuidv4 } from 'uuid'
import EmailForm from './EmailForm.vue'
import { Notyf } from 'notyf'

class Uploader {
  constructor(file, url, uid, progressCallback, finishCallback) {
    this.file = file
    this.url = url
    this.upload = new DirectUpload(this.file, this.url, this)
    this.uid = uid
    this.progressCallback = progressCallback
    this.finishCallback = finishCallback
  }

  startUpload() {
    this.upload.create((error, blob) => {
      if (error) {

      } else {
        console.log(blob.signed_id)
        if (this.finishCallback) {
          this.finishCallback(this.uid, blob.signed_id)
        }
      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    if (this.progressCallback) {
      this.progressCallback(this.uid, event.loaded)
    }
  }
}

export default {
  props: ["property", "user"],
  data: function(){
    return {
      uploads: [],
      property_files: [],
    }
  },
  components: {
    EmailForm
  },
  created() {
    this.progress = {
      need_update: false
    }
    setInterval(() => {
      this.progress.need_update = true
    }, 1000)
  },
  mounted: function() {    
    this.fetchPropertyFiles()
  },
  methods: {
    sendEmail(form) {
      const notyf = new Notyf({
        position: {
          x: 'center',
          y: 'top',
        }
      });
      axios.post(`/properties/${this.$route.params.id}/request_file.json`, form)
      .then(response => {
        this.$bvModal.hide('request-file')
        notyf.success("Email request sent!")
      })
      .catch((error) => {
        notyf.error("Oops error sending request")
      })
    },
    fileChanged(e) {
      let files = e.target.files
      this.uploadFiles(files)
    },

    uploadFiles(files) {
      let url = document
        .querySelector('meta[name="direct-upload-url"]')
        .getAttribute('content')
 
      for (var i=0; i < files.length; i++) {
        let uid = uuidv4()
        let uploader = new Uploader(files[i], url, uid, this.progressCallback, this.finishCallback)
        this.uploads.push(
          {
            uid: uid,
            name: files[i].name,
            progress: 0,
            filesize: files[i].size
          }
        )
        uploader.startUpload()
      }
      this.$refs.input_file.value = ""
    },

    progressCallback(uid, loaded) {
      if (this.progress.need_update) {
        this.progress.need_update = false
        console.log('rendering')
        let index = this.uploads.findIndex((upload) => upload["uid"] == uid)
        this.uploads[index]["progress"] = loaded
      }
    },

    finishCallback(uid, blobSignedId) {
      const params =  { 
        property_file: {
          file: blobSignedId
        }
      }

      axios.post(`/properties/${this.$route.params.id}/property_files.json`, params)
      .then(response => {
        let index = this.uploads.findIndex((upload)=>upload["uid"] == uid)
        this.uploads.splice(index, 1)
        this.fetchPropertyFiles()
      })
      .catch(error => {
        
      })
    },

    fetchPropertyFiles() {
      axios.get(`/properties/${this.$route.params.id}/property_files.json`)
        .then(response => {
          this.property_files = response.data.property_files
        })
    },

    fileDropped(event) {
      event.preventDefault()
      event.stopPropagation()
      this.uploadFiles(event.dataTransfer.files)
    },

    deletePropertyFile(propertyFile) {
      axios.delete(`/properties/${this.$route.params.id}/property_files/${propertyFile.id}.json`)
        .then(response => {
          let index = this.property_files.findIndex(file => file.id == propertyFile.id)
          this.property_files.splice(index, 1)
        })
    },

    progressPercentage(upload) {
      return Math.round(upload.progress * 100.0 / upload.filesize)
    }
  }
  
}
</script>

<style scoped>
  .upload-zone {
    border: 2px dashed #40a33f;
    padding: 20px;
    text-align: center;
    border-radius: 25px;
  }

  .progress-bar-container {
    border: 1px solid #40a33f;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .progress-bar {
    background-color: #40a33f;
    border-radius: 10px;
  }

  .file-info {
    position: absolute;
  }
</style>