<template>
  <PropertyTableLayout>
    <template v-slot:search>
      <PropertySearch />
    </template>
    
    <template v-slot:loans>
      <SearchInfo :property-count="properties.length" :total-property-count="pagy.count"/>
    
      <table :class="`table table-bordered ${hoverableClass}`">
        <thead>
          <tr>
            <th><SortableTableHeader title="Status" column="status" /></th>
            <th>Property Name</th>
            <th>Property Type</th>
            <th><SortableTableHeader title="Special Servicer" column="properties.special_servicer" /></th>
            <th><SortableTableHeader title="City" column="city" /></th>
            <th><SortableTableHeader title="State" column="state" /></th>
            <th><SortableTableHeader title="SF/Units" :column="squareFeetUnitColumn" /></th>
            <th><SortableTableHeader title="Total Balance" column="total_balance" /></th>
            <th v-if="declining_delinquency">Previous Delinquency</th>
            <th><SortableTableHeader title="Delinquency Status" column="properties.delinquency_status" /></th>
            <th v-if="commentarySearch()">Servicer Commentary</th>
          </tr>
        </thead>
        <tbody v-if="!isFetching">
          <tr v-for="property in properties" v-bind:key="property.id" :class="propertyRowClass(property)">
            <TagInput :initialStatuses="underwritingStatusText(property.underwriting_statuses)" :property="property"></TagInput>
            <td><router-link :to="`/property/${property.id}`">{{ property.name }}</router-link></td>
            <td>{{ property.trepp_subtype }}</td>
            <td>{{ property.special_servicer }}</td>
            <td>{{ property.city }}</td>
            <td>{{ property.state }}</td>
            <td>{{ commaDelimited(units(property)) }}</td>
            <td>${{ commaDelimited(property.total_balance) }}</td>
            <td v-if="declining_delinquency">{{ property.previous_month_loan.delinquency_status }}</td>
            <td>{{ property.delinquency_status }}</td>
            <Highlight :property="property" v-if="commentarySearch()" />
          </tr>
        </tbody>
      </table>
    </template>
  </PropertyTableLayout>
</template>

<script>
import axios from 'axios'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

import Highlight from '../components/Highlight.vue'
import TagInput from '../components/TagInput.vue'
import SearchInfo from '../components/SearchInfo.vue'
import SortableTableHeader from '../components/SortableTableHeader.vue'
import PropertyTableLayout from '../PropertyTableLayout.vue'
import UnderwritingStatus from '../components/UnderwritingStatus.vue'
import PropertySearch from '../PropertySearch.vue'

import { mapFields } from 'vuex-map-fields'

export default {
  components: { 
    PropertyTableLayout,
    Highlight, 
    SortableTableHeader,
    SearchInfo,
    UnderwritingStatus,
    TagInput,
    PropertySearch, 
  },
  data() {
    return {
      selected_underwriting_statuses: []
    }
  },
  computed: {
    ...mapFields([
      'propertyData.properties',
      'propertyData.pagy',
      'isFetching',
      'isFetchingInfiniteScroll',
      'search.commentary',
      'search.declining_delinquency',
      'selectedPropertyId',
      'search.property_type'
    ]),
    hoverableClass: function() {
      if (this.selectedPropertyId == -1){
        return ' table-hover'
      }
      
      return ''
    },
    squareFeetUnitColumn: function() {
      if (this.property_type == 'LO-' || this.property_type == 'MF-') {
        return 'number_of_units'
      } else {
        return 'square_feet'
      }
    }
  },
  methods: {
    underwritingStatusText(statuses) {
      if (statuses.length > 0) {
        return statuses.map(s => s.name).sort()
      }
      
      return ['Unlooked']
    },
    propertyRowClass: function(property) {
      if (this.selectedPropertyId == property.id) {
        return 'highlight'
      }
      return ''
    },

    units(property) {
      if (property.number_of_units) {
        return property.number_of_units
      } else {
        return property.square_feet
      }
    },
    date(commentary_date) {
      if (commentary_date == undefined || commentary_date == null) {
        return commentary_date;
      }
      
      var date = new Date(commentary_date + "T00:00:00")
      
      return date.toLocaleDateString('en-us', { year:"numeric", month:"short"})
    },
    commentarySearch() {
      return this.commentary != ''
    },
    commaDelimited(value) {
      if (value == undefined || value == null) {
        return value;
      }
    
      return value.toLocaleString();
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.id) {
      this.$store.commit("setSelectedPropertyId", parseInt(to.params.id))
    } else if (to.path == "/") {
      this.$store.commit("setSelectedPropertyId", -1)
    }
    
    next()
  },

  mounted() {
    window.onscroll = () => {
      if (this.pagy.page == this.pagy.last) {
        return;
      }
      
      let navbarHeight = 64.0
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight + navbarHeight >= document.documentElement.offsetHeight;
      
      if (bottomOfWindow && !this.isFetchingInfiniteScroll) {
        this.$store.dispatch('search', true)
      }
    }
  }
}
</script>

<style scoped>
  table th {
    text-align: left;
    color: white;
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    background-color: #4CAF50 !important;
    z-index: 10;
  }

  table th a, table th a:visited {
    color: #FFF;
    cursor: pointer;
  }

  .highlight {
    background-color: #e7e7e7;
  }

  .action-link {
    color: #0d6efd;
    text-decoration: underline;
  }
</style>
