<template>
  <form>
    <div class="input-group mb-3">
      <label class="input-group-text" for="inputGroupSelect01">Set a Callback Reminder?</label>
      <input type="datetime-local" v-model="callback_reminder_at" class="form-control">
      <button type="submit" class="btn btn-primary" @click.prevent="updateProperty">Submit</button>
    </div>
    
    <p v-if="property.callback_reminder_at">Callback reminder set for <b>{{ dateText(property.callback_reminder_at) }}</b></p>
    <p v-else>No callback reminder set</p>
  </form>
</template>

<script>

import axios from "axios";

import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

export default {
  props: ['property'],
  data() {
    return {
      callback_reminder_at: null
    }
  },
  mounted() {
    if (this.property.callback_reminder_at != null) {
      this.callback_reminder_at = this.convertToIsoDate(this.property.callback_reminder_at)
    } else {
      this.callback_reminder_at = this.getDateStringNow()
    }
  },
  methods: {
    disableArrowKey(status) {
      this.$store.commit("setArrowKeyDisabled", status)
    },
    convertToIsoDate(fromDate) {
      let date = new Date(fromDate)
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
      date.setMilliseconds(null)
      date.setSeconds(null)
      return date.toISOString().slice(0, -1)
    },
    getDateStringNow() {
      const now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      now.setMilliseconds(null)
      now.setSeconds(null)
      return now.toISOString().slice(0, -1);
    },
    dateText(date) {
      var date = new Date(date)
    
      return date.toLocaleString()
    },
    updateProperty: function() {
      const notyf = new Notyf({
        position: {
          x: 'center',
          y: 'top',
        }
      });
            
      const property =  {
        callback_reminder_at: this.callback_reminder_at
      }

      axios.put(`/properties/${this.$route.params.id}.json`, { property })
      .then(response => {
        notyf.success('Property updated!');
        
        this.property.callback_reminder_at = this.callback_reminder_at
      })
      .catch(error => {
        notyf.error('Oops, notes could not be updated');
      })
    },
  }
}
</script>

<style scoped>
  .action-buttons {
    text-align: right;
  }
</style>