<template>
  <transition name="slide-fade">
    <div class="property px-4 py-2" v-if="isFetching == false">
      <a href="#" @click.prevent="close">Close</a>
            
      <router-link :to="`/property/${previousPropertyId}`" v-if="previousPropertyId">
        <span class="nav-dir"><b-icon icon="arrow-left-short"></b-icon>Prev</span>
      </router-link>
      
      <router-link :to="`/property/${nextPropertyId}`" v-if="nextPropertyId">
        <span class="nav-dir">Next<b-icon icon="arrow-right-short"></b-icon></span>
      </router-link>
      
      <div class="container-fluid p-0">
        <div class="row mt-3">
          <div class="col-5">
            <h4 class="mb-0">{{ property.name }} - {{ property.city }}, {{ property.state }}</h4>
            <p>{{ property.address }}</p>
          </div>
          <div class="col-7">
            <div class="d-flex justify-content-end">
              <div class="item">
                <b>{{ property.delinquency_status }}</b>
                <p>Delinquency</p>
              </div>
              <div class="item">
                <b>{{ property.year_built }}</b>
                <p>Built</p>
              </div>
              <div class="item">
                <b>{{ commaDelimited(this.units) }}</b>
                <p>{{ this.unitLabel() }}</p>
              </div>
              <div class="item">
                <b>{{ property.trepp_subtype }}</b>
                <p>Trepp Subtype</p>
              </div>
              <div class="item">
                <b>{{ this.status }}</b>
                <p>Status</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-8">
            <tabs mode="light">
              <tab title="Map">
                <div id="map" ref="map"></div>
                <SponsoredProperties :property="property" :key="property.id"/>
              </tab>
              
              <tab title="Notes">
                <Notes :partner="this.partner" :property="property" :initialReasons="property.ai_statuses" :underwritingStatuses="property.underwriting_statuses" :key="property.id" />
                
                <div v-show="property.mezz_loans.length > 0">
                  <p>Property has mezz</p>
                </div>
              </tab>
              
              <tab title="Commentary">
                <Commentary :property="property" :key="property.id" />
              </tab>
              
              <tab title="Pictures">
                <GoogleImages :property="property" :key="property.id" />
              </tab>
              
              <tab title="Outreach">
                <Outreach :property="property" :key="property.id" />
              </tab>

              <tab title="Files">
                <FileUploader :property="property" :key="property.id" :user="$store.state.current_user"/>
              </tab>
            </tabs>
          </div>
          
          <PropertySidebar :property="property" :key="property.id" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";

import PropertySidebar from '../PropertySidebar.vue'
import Tab from '../components/Tab.vue'
import Tabs from '../components/Tabs.vue'
import Notes from '../components/Notes.vue'
import Outreach from '../components/outreach/Outreach.vue'
import Commentary from '../components/Commentary.vue'
import GoogleImages from '../components/GoogleImages.vue'
import FileUploader from '../components/FileUploader.vue'
import GooglePlaceRating from '../components/GooglePlaceRating.vue'
import PlacerRanking from '../components/PlacerRanking.vue'
import SponsoredProperties from '../components/SponsoredProperties.vue'

import isEmpty from 'lodash'

import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

export default {
  components: { 
    Tab,
    Tabs,
    GoogleImages,
    Notes,
    FileUploader,
    Outreach,
    Commentary,
    PlacerRanking,
    PropertySidebar,
    SponsoredProperties
  },
  data: function() {
    return {
      property: {},
      isFetching: true
    }
  },

  methods: {
    close: function() {
      if (this.$route.name == "PropertyDashboard") {
        this.$router.push({ path: '/dashboard' })
      }   
      
      if (this.$route.name == "Property") {
        this.$router.push({ path: '/' })
      }
      
      if (this.$route.name == "PropertyRefinance") {
        this.$router.push({ path: '/refinance' })
      }
      
      this.$store.commit('showSidebar', true);
    },
    updateProperty: function() {
      const notyf = new Notyf({
        position: {
          x: 'center',
          y: 'top',
        }
      });
            
      const property =  {
        ff_notes: this.property.ff_notes,
        rm_notes: this.property.rm_notes
      }

      axios.put(`/properties/${this.$route.params.id}.json`, { property })
      .then(response => {
        notyf.success('Property updated!');
      })
      .catch(error => {
        notyf.error('Oops, notes could not be updated');
      })
    },
    fetchProperty: function(id) {
      axios.get(`/properties/${id}.json`)
      .then(response => {
        this.property = response.data;
        this.isFetching = false;
      })
      .catch(error => {
        console.log(error);
      })
    },
    initMap: function() {
      const myLatLng =  { lat: this.property.latitude, lng: this.property.longitude }
      const map = new google.maps.Map(this.$refs.map, {
        zoom: 17,
        center: myLatLng,
        mapTypeId: google.maps.MapTypeId.HYBRID,
        labels: true
      });
      new google.maps.Marker({
        position: myLatLng,
        map,
        title: this.property.name,
      });
    },
    commaDelimited(value) {
      if (value == undefined || value == null) {
        return value;
      }

      return value.toLocaleString();
    },
    navigateByKey(event) {
      if (this.$store.state.arrowKeyDisabled) {
        return
      }
      event.preventDefault();

      if (event.key == 'ArrowDown') {
        if (this.nextPropertyId) {
          this.$router.push(`/property/${this.nextPropertyId}`)
        }
      } else if (event.key == 'ArrowUp') {
        if (this.previousPropertyId) {
          this.$router.push(`/property/${this.previousPropertyId}`)
        }
      }
    },
    propertyIsRetail() {
      return this.property.normalized_property_type.startsWith('RT-')
    },
    propertyUsesUnits() {
      if (this.property.normalized_property_type == null) {
        return
      }
      
      return this.property.normalized_property_type.startsWith('LO-') || this.property.normalized_property_type.startsWith('MF-')
    },
    unitLabel(singular = false) {
      if (this.propertyUsesUnits()) {
        return singular ? "Unit" : "Units"
      } else {
        return "Sq Ft"
      }
    },
  },
  beforeRouteUpdate (to, from, next) {
    this.fetchProperty(to.params.id);

    next();
  },
  beforeRouteLeave(to, from, next) {
    if (to.path == '/') {
      this.$store.commit('showSidebar', true);
    }

    next();
  },
  mounted() {
    this.fetchProperty(this.$route.params.id);

    this.$store.commit('showSidebar', false);
    this.$store.commit('setSelectedPropertyId', parseInt(this.$route.params.id))

    document.addEventListener("keyup", this.navigateByKey)
  },

  destroyed() {
    document.removeEventListener("keyup", this.navigateByKey)
  },

  updated() {
    this.initMap();
  },
  computed: {
    alphaMapURL() {
      return `https://app.alphamap.com/?lookup=${this.property.address} ${this.property.city }, ${this.property.state} ${this.property.zipcode}`
    },
    status() {
      if (this.property.loan.special_service_status == 'Yes') {
        return "Special Serviced"
      }
      
      if (this.property.loan.watchlist_status == 'Yes') {
        return "Watchlist"
      } 
      
      if (this.property.loan.watchlist_status == 'No' && this.property.loan.special_service_status == 'No') {
        return "Performing"
      }
    },
    units() {
      if (this.propertyUsesUnits()) {
        return this.property.number_of_units
      } else {
        return this.property.square_feet
      }
    },
    nextPropertyId() {
      return this.$store.getters.adjacentPropertyId(1)
    },
    previousPropertyId() {
      return this.$store.getters.adjacentPropertyId(-1)
    },
    partner() {
      if (this.property.normalized_property_type == null) {
        return
      }
      
      if (this.property.normalized_property_type.startsWith('LO-')) {
        return 'GF'
      } 
      
      if (this.property.normalized_property_type.startsWith('RT-')) {
        return 'RM'
      }
      
      return 'Partner'
    }
  }
}
</script>

<style lang="scss" scoped>
  .item {
    margin-left: 35px;
    text-align: center;
    
    p {
      color: #CCC;
      font-size: .9rem;
    }
  }
  
  table {
    table-layout: fixed;
    width: 100%;
  }
  
  .table-sm {
    font-size: .75rem;
    border-color: #DDD
  }

  tr td:nth-child(2) {
    text-align: right;
  }

  .property {
    position: fixed;
    height: 100%;
    background-color: white;
    left: 20%;
    width: 80%;
    top: 0px;
    box-shadow: 2px 2px 20px 1px;
    overflow: scroll;
    z-index: 20;
  }
  
  #map {
    height: 700px;
    width: 100%;
  }
  
  p {
    margin-bottom: 0px;
  }
  
  .banner {
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: #4CAF50;
    color: #FFF;
  }
  
  .content-box-green {
    margin-bottom: 10px;
  }
    
  .content-box-green .content {
    overflow: hidden;
    padding: 10px;
    font-size: 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #4CAF50;
  }
  
  .content-box-green .title {
    height :30px;
    line-height: 30px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: #4CAF50;
    font-size: 12px;
    font-weight: bold;
    display: block;
    color: white;
    display: block;
    padding: 0px 5px;
    border: 1px solid #4CAF50;
    border-bottom: none;
  }
  
  .slide-fade-enter-active, .slide-fade-leave-active  {
    transition: all .3s ease;
  }
  
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }

  .nav-dir {
    padding: 0 10px 0 10px;
  }
</style>