<template>
  <div v-if="isDataAvailable">
    <input v-if="!processing" type="submit" value="Export to JSON" class="btn btn-secondary" @click="exportToJson"/>
    <span v-else>Processing JSON...</span>
  </div>
</template>

<script>
import axios from "axios"

export default {
  data() {
    return {
      processing: false
    }
  },
  computed: {
    isDataAvailable() {
      return this.$store.state.propertyData.properties.length > 0
    }
  },
  methods: {
    exportToJson() {
      this.processing = true
      let params = {
        ...this.$store.state.search
      }
      
      axios.post("/json_properties/export", params)
        .then((response)=>{
          this.processing = false
          location.href = `/json_properties/download?filename=${response.data.filename}`
        })
    }
  }
}
</script>