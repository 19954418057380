// src/utils/filterParams.js

export const filterParams = (params) => {
  const cleanedParams = {};

  Object.entries(params).forEach(([key, value]) => {
    // Skip values that are empty or default
    if (
      (Array.isArray(value) && value.length === 0) ||             // Empty arrays
      (typeof value === 'string' && value.trim() === '') ||       // Empty strings
      (typeof value === 'number' && value === 0) ||               // Default numbers (like population: 0)
      (typeof value === 'object' &&                              // Empty range objects, e.g., { min: '', max: '' }
        value !== null &&
        Object.values(value).every((v) => v === ''))
    ) {
      return; // Skip this entry
    }

    // Add non-empty parameters to cleanedParams
    cleanedParams[key] = value;
  });
    
  return cleanedParams;
};
