import Vue from 'vue'
import VueRouter from 'vue-router'

import Property from '../pages/Property.vue'
import Properties from '../pages/Properties.vue'
import Dashboard from '../pages/Dashboard.vue'
import Refinance from '../pages/Refinance.vue'

Vue.use(VueRouter)

const routes = [
  { 
    path: '/', 
    name: 'Properties',
    component: Properties,
    children: [{
      name: "Property",
      path: '/property/:id', 
      components: {
        property: Property,
      }
    }]
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    children: [{
      name: "PropertyDashboard",
      path: '/dashboard/property/:id', 
      components: {
        property: Property
      }
    }]
  },
  {
    path: '/refinance',
    name: 'Refinance',
    component: Refinance,
    children: [{
      name: "PropertyRefinance",
      path: '/refinance/property/:id', 
      components: {
        property: Property
      }
    }]
  }
]

const router = new VueRouter({routes: routes})

export default router