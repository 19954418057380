<template>
  <div class="content">
    <div v-for="(loan_pieces, commentary_date, index) in loan_pieces" :key="commentary_date">
      <p class="header mb-1">
        {{ status(loan_pieces) }} Commentary As of- {{ commentary_date }} 
      </p>
      <p v-for="loan_piece in loan_pieces">
        {{ loan_piece.servicer_commentary }}
        
        <AssignLoanPieces :loan_piece="loan_piece" />
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import AssignLoanPieces from './AssignLoanPieces.vue'

export default {
  components: {
    AssignLoanPieces
  },
  props: [
    "property"
  ],
  data: function(){
    return {
      loan_pieces: []
    }
  },
  mounted: function(){
    this.fetchCommentaries()
  },
  methods: {
    fetchCommentaries() {
      axios.get(`/properties/${this.property.id}/loan_pieces.json`)
        .then(response => {
          this.loan_pieces = response.data
        })
        .catch(error => {
          console.log('Oops, servicer commentary could not be loaded.');
        })
    },
    loan_piece_ids(loan_pieces) {
      return loan_pieces.map(x => x.id)
    },
    commentary(loan_pieces) {
      return loan_pieces.map(loan_piece => loan_piece.servicer_commentary).join("<br /><br />")
    },
    status(loan_pieces) {
      if (loan_pieces[0].watchlist_status == 'Yes') {
        return 'Watchlist'
      } else {
        return 'Special Serviced'
      }
    }
  }
}
</script>

<style scoped>
.header {
  font-weight: bold;
  background-color: #bdcbdf;
  color: #000;
}
</style>
