<template>
  <div>
    <multiselect v-model="current_underwriting_statuses" 
      :multiple="true" 
      :options="all_underwriting_statuses" 
      :searchable="true" 
      :taggable="true" 
      :tag-placeholder="`Add this as new status`" 
      :close-on-select="true"
       track-by="name" 
       label="name"  
       placeholder="Type status" 
       @tag="addStatus">
     </multiselect>
  </div>
</template>

<script>
import axios from "axios"
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  props: ["property"],
  data() {
    return {
      current_underwriting_statuses: [],
      all_underwriting_statuses: []
    }
  },
  mounted() {
    this.fetchUnderwritingStatus()
    this.fetchAllUnderwritingStatus()
  },
  watch: {
    current_underwriting_statuses: function(newStatuses, oldStatuses) {
      this.$emit("status-changed", newStatuses)
    }
  },
  methods: {
    fetchUnderwritingStatus() {
      axios.get(`/properties/${this.property.id}/underwriting_statuses.json`)
        .then(response => {
          this.current_underwriting_statuses = response.data.underwriting_statuses
        })
    },
    fetchAllUnderwritingStatus() {
      axios.get(`/underwriting_statuses.json`)
        .then(response => {
          console.log(response)
          this.all_underwriting_statuses = response.data.underwriting_statuses
        })
    },
    addStatus(newStatus) {
      this.current_underwriting_statuses.push({ name: newStatus })
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>