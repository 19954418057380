<template>
  <div class="d-inline">
    <button v-b-modal="`modal-${loan_piece.id}`">Assign</button>
  
    <b-modal :id="`modal-${loan_piece.id}`" size="lg" title="Assign Loan Piece" :hide-footer="true">
      <b>Which property would you like to assign this loan piece to?</b>
      
      <p>{{ loan_piece.servicer_commentary }}</p>
      
      <input type="text" placeholder="Property Id" v-model="property_id" />
      
      <button @click="update_loan_piece">Submit</button>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios"

import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

export default {
  props: [
    "loan_piece"
  ],
  data: function(){
    return {
      property_id: null
    }
  },
  methods: {
    update_loan_piece() {
      let url = `/properties/${this.loan_piece.property_id}/loan_pieces/${this.loan_piece.id}`
      
      let params = {
        loan_piece: {
          property_id: this.property_id
        }
      }
      
      const notyf = new Notyf({
        position: {
          x: 'center',
          y: 'top',
        }
      });
      
      axios.put(url, params)
        .then(response => {
          console.log(response)
          notyf.success("Loan Piece reassigned!");
        })
        .catch(error => {
          console.log('Oops, loan piece could not be loaded.');
        })
    }
  }
}
</script>

<style scoped>
</style>