<template>
  <PropertyTableLayout>
    <template v-slot:search>
      <form class="search">
        <div>
          <label class="col-form-label">Property Type</label>
          <select name="property_type" class="form-select" v-model="property_type" @change="refinance">
            <option v-for="(type) in $store.getters.allowedPropertyTypeFilters" :value="type[0]" :key="type[0]" selected="selected">
              {{ type[1] }}
            </option>
          </select>
        </div>
        <div>
          <label class="col-form-label">Maturity Date</label>
          <b-form-datepicker :calendar-width="`400px`" v-model="maturity_date" @input="refinance"></b-form-datepicker>
        </div>
        <div>
          <label class="col-form-label">Office Debt Yield</label>
          <div class="input-group">
            <input type="text" class="form-control" v-model="debt_yield_shortfall.office" @change="refinance">
            <span class="input-group-text">%</span>
          </div>
        </div>
        <div>
          <label class="col-form-label">Retail Debt Yield</label>
          <div class="input-group">
            <input type="text" class="form-control" v-model="debt_yield_shortfall.retail" @change="refinance">
            <span class="input-group-text">%</span>
          </div>
        </div>
        <div>
          <label class="col-form-label">Multifamily Debt Yield</label>
          <div class="input-group">
            <input type="text" class="form-control" v-model="debt_yield_shortfall.multifamily" @change="refinance">
            <span class="input-group-text">%</span>
          </div>
        </div>
        <div>
          <label class="col-form-label">Industrial Debt Yield</label>
          <div class="input-group">
            <input type="text" class="form-control" v-model="debt_yield_shortfall.industrial" @change="refinance">
            <span class="input-group-text">%</span>
          </div>
        </div>
        <div>
          <label class="col-form-label">Hotel Debt Yield</label>
          <div class="input-group">
            <input type="text" class="form-control" v-model="debt_yield_shortfall.hotel" @change="refinance">
            <span class="input-group-text">%</span>
          </div>
        </div>
        <div>
          <label class="col-form-label">Self Storage Debt Yield</label>
          <div class="input-group">
            <input type="text" class="form-control" v-model="debt_yield_shortfall.self_storage" @change="refinance">
            <span class="input-group-text">%</span>
          </div>
        </div>
        <div>
          <label class="col-form-label">Mixed Use Debt Yield</label>
          <div class="input-group">
            <input type="text" class="form-control" v-model="debt_yield_shortfall.mixed_use" @change="refinance">
            <span class="input-group-text">%</span>
          </div>
        </div>
        <div class="mb-3">
          <label class="col-form-label">Underwriting Status</label>
          
          <label>
            <input type="checkbox" v-model="select_all_statuses" @change="selectAllStatuses">
            (Select All)
          </label>
          
          <label v-for="(underwritingStatus, index) in underwritingStatuses" v-bind:key="index">
            <input type="checkbox" :value="underwritingStatus" v-model="status" @change="refinance">
            {{ capitalize(underwritingStatus) }}
          </label>
        </div>
      </form>
    </template>
    <template v-slot:loans>
      <h5>Showing {{ pagy.count }} properties</h5>
      
      <table :class="`table table-bordered ${hoverableClass}`">
      <thead>
        <tr>
          <th><SortableTableHeader title="Status" column="status" form="refinance" /></th>
          <th>Property Name</th>
          <th>Property Type</th>
          <th><SortableTableHeader title="City" column="city" form="refinance" /></th>
          <th><SortableTableHeader title="State" column="state" form="refinance" /></th>
          <th>NOI</th>
          <th><SortableTableHeader title="Current Loan" column="total_balance" form="refinance" /></th>
          <th>New Loan</th>
          <th><SortableTableHeader title="Shortfall" column="" form="refinance"/></th>
          <th><SortableTableHeader title="Delinquency Status" column="properties.delinquency_status" form="refinance"/></th>
          <th>Maturity Date</th>
        </tr>
      </thead>
      <tbody v-if="!isFetching">
        <tr v-for="property in properties" v-bind:key="property.id" :class="propertyRowClass(property)">
          <TagInput :initialStatuses="underwritingStatusText(property.underwriting_statuses)" :property="property"></TagInput>
          <td><router-link :to="`/refinance/property/${property.id}`">{{ property.name }}</router-link></td>
          <td>{{ property.trepp_subtype }}</td>
          <td>{{ property.city }}</td>
          <td>{{ property.state }}</td>
          <td>${{ commaDelimited(property.loan.noi) }}</td>
          <td>${{ commaDelimited(property.total_balance) }}</td>
          <td>${{ commaDelimited(newLoanAmount(property))}}</td>
          <td>${{ commaDelimited(shortfall(property)) }}</td>
          <td>{{ property.loan.delinquency_status }}</td>
          <td>{{ date(property.loan.maturity_date) }}</td>
        </tr>
      </tbody>
    </table>
    </template>
  </PropertyTableLayout>
</template>

<script>
import axios from 'axios'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

import PropertyTableLayout from '../PropertyTableLayout.vue'
import Highlight from '../components/Highlight.vue'
import SortableTableHeader from '../components/SortableTableHeader.vue'
import SearchInfo from '../components/SearchInfo.vue';
import UnderwritingStatus from '../components/UnderwritingStatus.vue'
import TagInput from '../components/TagInput.vue'

import { mapFields } from 'vuex-map-fields'

export default {
  components: { 
    PropertyTableLayout,
    Highlight, 
    SortableTableHeader,
    SearchInfo,
    UnderwritingStatus,
    TagInput
  },
  data() {
    return {
      select_all_statuses: false
    }
  },
  computed: {
    ...mapFields([
      'refinanceData.properties',
      'refinanceData.pagy',
      'isFetching',
      'isFetchingInfiniteScroll',
      'refinance.maturity_date',
      'refinance.debt_yield_shortfall',
      'refinance.property_type',
      'refinance.status',
      'underwritingStatuses',
      'selectedPropertyId'
    ]),
    hoverableClass: function() {
      if (this.selectedPropertyId == -1){
        return 'table-hover'
      }
    },
    squareFeetUnitColumn: function() {
      if (this.property_type == 'LO-' || this.property_type == 'MF-') {
        return 'number_of_units'
      } else {
        return 'square_feet'
      }
    }
  },
  methods: {
    capitalize(string) {
      if (string == null || string.length == 0) {
        return ''
      }
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    refinance: function() {
      this.$store.dispatch('refinance')
    },
    selectAllStatuses: function() {
      if (this.select_all_statuses) {
        this.$store.commit('fillAllStatuses')
      } else {
        this.$store.commit('clearStatuses')
      }
      
      this.refinance()
    },
    underwritingStatusText(statuses) {
      if (statuses.length > 0) {
        return statuses.map(s => s.name).sort()
      }
      
      return ['Unlooked']
    },
    propertyRowClass: function(property) {
      if (this.shortfall(property) > 0) {
        return 'bg-yellow'
      }
      
      if (this.selectedPropertyId == property.id) {
        return 'highlight'
      }
      
      return ''
    },
    units(property) {
      if (property.number_of_units) {
        return property.number_of_units
      } else {
        return property.square_feet
      }
    },
    commaDelimited(value) {
      if (value == undefined || value == null) {
        return value;
      }
    
      return value.toLocaleString("en", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    date(commentary_date) {
      var date = new Date(commentary_date + "T00:00:00")
      
      return date.toLocaleDateString('en-us', { year:"numeric", month:"short"})
    },
    shortfall(property) {
      let shortfall = property.total_balance - this.newLoanAmount(property)
                
      if (shortfall > 0) {
        return shortfall
      } else {
        return 0
      }
    },
    newLoanAmount(property) {
      return property.loan.noi / (this.debt_yield_shortfall[this.debt_yield_map(property)] / 100)
    },
    shortfallAmount(property) {
      let excessProceeds = property.total_balance - (this.valuation(property) * ( this.shortfall.ltv / 100 ));
      
      if (excessProceeds < 0) {
        return 0
      } else {
        return excessProceeds
      }
    },
    valuation(property) {
      if (this.shortfall.cap_rate == undefined || this.shortfall.cap_rate == null || this.shortfall.cap_rate == '') {
        return '-';
      }
      
      return property.loan.noi / ( this.shortfall.cap_rate / 100 )
    },
    debt_yield_map(property) {
      const mapping = {
        'RT-': 'retail',
        'OF-': 'office',
        'LO': 'hotel',
        'MF-': 'multifamily',
        'SS': 'self_storage',
        'IN': 'industrial',
        'MU': 'mixed_use'
      };
      
      for (const prefix in mapping) {
        if (property.normalized_property_type?.startsWith(prefix)) {
          return mapping[prefix];
        }
      }
      
      return 'default';
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.id) {
      this.$store.commit("setSelectedPropertyId", parseInt(to.params.id))
    } else if (to.path == "/") {
      this.$store.commit("setSelectedPropertyId", -1)
    }
    
    next()
  },

  mounted() {
    window.onscroll = () => {
      if (this.pagy.page == this.pagy.last) {
        return;
      }
      
      let navbarHeight = 64.0
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight + navbarHeight >= document.documentElement.offsetHeight;
      
      if (bottomOfWindow && !this.isFetchingInfiniteScroll) {
        this.$store.dispatch('refinance', true)
      }
    }
  }
}
</script>

<style lang="scss">
table th {
  text-align: left;
  color: white;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  background-color: #4CAF50 !important;
  z-index: 10;
}

table th a, table th a:visited {
  color: #FFF;
  cursor: pointer;
}

.col-form-label {
  font-weight: bold;
}
.bg-yellow {
  background-color: yellow !important;
}
form.search {
  label {
    display: block;
  }
  
  div {
    margin-bottom: 5px;
  }
  
  input[type=text], select {
    font-size: 0.75rem;
  }

  p, label {
    font-size: 0.75rem;
  }

  p {
    margin-bottom: 0px;
  }
  
  .b-form-btn-label-control.form-control > .form-control {
    font-size: 12px;
  }
}

</style>