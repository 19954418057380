<template>
  <div>
    <div>
      <label>Recipient:</label>
      <input type="text" v-model="form.recipient" required class="form-control">
    </div>
    <div class="mt-3">
      <label>Subject:</label>
      <input type="text" v-model="form.subject" required class="form-control">
    </div>
    <div class="mt-3">
      <label>Body:</label>
      <b-form-textarea rows="5" v-model="form.body" @focus="disableArrowKey(true)" @blur="disableArrowKey(false)"></b-form-textarea>
    </div>
    <div class="mt-3">
      <label>Sender:</label>
      <input type="text" v-model="form.sender" required class="form-control">
    </div>
    <div class="mt-2 action-buttons">
      <button class="btn btn-primary" @click="submitForm" :disabled="form.recipient == ''">Send</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["property", "defaultSender"],
  data() {
    return {
      form: {
        subject: `Plese send Trepp - ${this.property.name}`,
        recipient: 'jake@ffcapitalgroup.com',
        sender: this.defaultSender,
        body: `Please reply to this email with the Trepp and upload it to FFDealFinder for ${this.property.name} at ${this.property.address} ${this.property.city} ${this.property.state} ${this.property.zipcode}. Thanks!`
      }
    }
  },
  methods: {
    disableArrowKey(status) {
      this.$store.commit("setArrowKeyDisabled", status)
    },
    submitForm() {
      this.$emit("formSubmit", this.form)
    }
  }
}
</script>

<style scoped>
  .action-buttons {
    text-align: right;
  }
</style>