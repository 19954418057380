<template>
  <a :class="cssClass" @click="applySort()">{{ title }}</a>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  props: {
    title: {
      type: String
    },
    column: {
      type: String
    },
    form: {
      type: String,
      default: 'search'
    } // 'search' or 'dashboard' accepted
  },
  data() {
    return {
      direction: 'asc'
    }
  },
  computed: {
    ...mapFields({
      searchSort: 'search.sort',
      dashboardSort: 'dashboard.sort',
      refinanceSort: 'refinance.sort'
    }),
    
    cssClass() {
      return {
        ['current ' + this.direction]: this[this.form + 'Sort'] == this.column
      }
    },
  },
  methods: {
    applySort() {
      if (this.form == 'search') {
        if (this.column == this.searchSort) {
          this.toggleDirection()
        }
      } 
      
      if (this.form == 'dashboard') {
        if (this.column == this.dashboardSort) {
          this.toggleDirection()
        }
      }
      
      if (this.form == 'refinance') {
        if (this.column == this.refinanceSort) {
          this.toggleDirection()
        }
      }
      
      this.$store.commit('applySort', {
        form: this.form,
        sort: this.column, 
        direction: this.direction 
      })
      
      this.$store.dispatch(this.form)
    },
    toggleDirection() {
      if (this.direction == "asc") {
        this.direction = 'desc';
      } else {
        this.direction = 'asc';
      }
    }
  }
}
</script>

<style>
  table th .current {
    padding-right: 12px;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 10px;
  }

  table th .asc {
    background-image: url('../svg/caret-up.svg');
  }

  table th .desc {
    background-image: url('../svg/caret-down.svg');
  }
  
  table th {
    cursor: pointer;
  }
</style>