<template>
  <form @submit.prevent="createLog" class="mb-3">
    <div>
      <div class="form-group row mb-2">
        <label class="col-sm-2 col-form-label">Company Name</label>
        <div class="col-sm-10">
          <input type="text" v-model="outreach_log.company_name" class="form-control" />
        </div>
      </div>
      
      <div class="form-group row mb-2">
        <label class="col-sm-2 col-form-label">Name</label>
        <div class="col-sm-10">
          <input type="text" v-model="outreach_log.person_name" class="form-control" />
        </div>
      </div>
  
      <div class="form-group row mb-2">
        <label class="col-sm-2 col-form-label">Phone Number</label>
        <div class="col-sm-10">
          <input type="text" v-model="outreach_log.person_phone" class="form-control" />
        </div>
      </div>
      
      <div class="form-group row mb-2">
        <label class="col-sm-2 col-form-label">Email</label>
        <div class="col-sm-10">
          <input type="text" v-model="outreach_log.person_email" required class="form-control" />
        </div>
      </div>
  
      <div class="form-group row mb-2">
        <label class="col-sm-2 col-form-label">Notes</label>
        <div class="col-sm-10">
          <textarea class="form-control" v-model="outreach_log.notes" @focus="disableArrowKey(true)" @blur="disableArrowKey(false)"></textarea>
        </div>
      </div>
      
      <div class="form-group row mb-2">
        <label class="col-sm-2 col-form-label">Time of Call</label>
        <div class="col-sm-10">
          <input type="datetime-local" v-model="iso_contact_date" class="form-control">
        </div>
      </div>
    </div>
    
    <button class="btn btn-primary" data-button="submit-log">Submit Log</button>
  </form>
</template>

<script>
import axios from "axios"

import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

export default {
  props: {
    log: Object
  },
  data: function(){
    return {
      outreach_log: {
        person_name: '',
        person_phone: '',
        person_email: '',
        notes: '',
        answered: false,
        outreach_type: 'Call',
        contact_date: null,
        callback_reminder_at: null,
      },
      iso_contact_date: '',
      iso_reminder_date: ''
    }
  },
  mounted() {
    if (this.log.contact_date != null) {
      this.iso_contact_date = this.convertToIsoDate(this.log.contact_date)
    } else {
      this.iso_contact_date = this.getDateStringNow()
    }
    
    this.outreach_log = this.log
  },
  methods: {
    disableArrowKey(status) {
      this.$store.commit("setArrowKeyDisabled", status)
    },
    convertToIsoDate(fromDate) {
      let date = new Date(fromDate)
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
      date.setMilliseconds(null)
      date.setSeconds(null)
      return date.toISOString().slice(0, -1)
    },
    getDateStringNow() {
      const now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      now.setMilliseconds(null)
      now.setSeconds(null)
      return now.toISOString().slice(0, -1);
    },
    logChanged(log) {
      this.edited_log = log;
    },
    resetOutreachLog() {
      this.outreach_log.notes = ''
      this.outreach_log.answered = false
      this.outreach_log.outreach_type = 'Call'
      this.outreach_log.contact_date = this.getDateStringNow()
    },
    createLog() {
      const notyf = new Notyf({
        position: {
          x: 'center',
          y: 'top',
        }
      });
      
      const propertyId = this.$route.params.id;
      
      axios.post(`/properties/${propertyId}/outreach_logs.json`, { outreach_log: this.outreach_log })
      .then(response => {
        notyf.success('Action logged!')
        this.resetOutreachLog()
        this.$emit('fetch-logs')
      })
      .catch(error => {
        notyf.error('Oops, notes could not be updated')
      })
    }
  },
  computed: {
    changeData() {
      return this.outreach_log
    }
  },
  watch: {
    changeData: {
      handler: function(val) {
        this.$emit("logChange", this.outreach_log)
      }, 
      deep: true
    },
    "iso_contact_date": function(newIsoContactDate, oldIsoContactDate) {
      let date = new Date(newIsoContactDate).toISOString().slice(0, -1)
      this.outreach_log.contact_date = date
      this.$emit("logChange", this.outreach_log)
    }
  }
}
</script>