<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Default?</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="contact in contacts" v-bind:key="contact.id">
          <td>{{contact.name}}</td>
          <td>{{contact.email}}</td>
          <td>{{(contact.is_default) ? `YES` : ` `}}</td>
          <td>
            <a class="action-link mr-5" @click="setAsDefaultContact(contact)">
              Make Default
            </a>
          </td>
          <td>
            <a v-b-modal="`contact-edit-${contact.id}`">
              <b-icon icon="pencil"/>
            </a>
            
            <b-modal :id="`contact-edit-${contact.id}`" :hide-footer="true">
              <PropertyOwner :initialContact="contact" @contactSubmit="updateContact"/>
            </b-modal>

            <b-icon v-if="contact.id != deleted_contact_id" class="mx-3" icon="trash" @click="askDeleteContact(contact.id)"/>
            <span v-else>
              <button @click="deleteContact">Continue</button>
              <button @click="cancelDeleteContact">Cancel</button>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <div v-if="creating_contact">
        <button class="btn btn-secondary" @click="exitCreatingContactMode">Cancel</button>
        <PropertyOwner :initialContact="new_contact" :key="property.id" @updateDone="updateOwnerDone"/>
      </div>
      <div v-else>
        <button class="btn btn-secondary" @click="enterCreatingContactMode">Add Contact</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import PropertyOwner from "./PropertyOwner.vue"

export default {
  props: ["property"],
  components: {
    PropertyOwner
  },
  data() {
    return {
      contacts: [],
      deleted_contact_id: null,
      creating_contact: false,
      new_contact: {
        name: '',
        email: '',
        phone_number: '',
        website: '',
        company_name: ''
      }
    }
  },
  methods: {
    resetNewContactForm() {
      this.new_contact.name = ''
      this.new_contact.email = ''
      this.new_contact.phone_number = ''
      this.new_contact.website = ''
      this.new_contact.company_name = ''
    },
    setAsDefaultContact(contact) {
      axios.patch(`/properties/${this.property.id}/contacts/${contact.id}/set_default.json`)
        .then(response => {
          this.fetchContacts()
        })
        .catch(error => {
        })
    },
    updateContact(contact) {
      axios.patch(`/properties/${this.property.id}/contacts/${contact.id}.json`, {
        contact: contact
      })
        .then(response => {
          this.$bvModal.hide(`contact-edit-${contact.id}`)
          this.fetchContacts()
        })
        .catch(error => {
        })
    },
    updateOwnerDone(contact) {
      this.exitCreatingContactMode()
      this.fetchContacts()
      this.resetNewContactForm()
    },
    enterCreatingContactMode() {
      this.creating_contact = true
    },
    exitCreatingContactMode() {
      this.creating_contact = false
    },
    askDeleteContact(contact_id) {
      this.deleted_contact_id = contact_id
    },
    cancelDeleteContact() {
      this.deleted_contact_id = null
    },
    deleteContact() {
      axios.delete(`/properties/${this.property.id}/contacts/${this.deleted_contact_id}.json`)
        .then(response => {
          let index = this.contacts.findIndex((contact)=> contact.id == this.deleted_contact_id)
          this.contacts.splice(index, 1)
        })
        .catch(error => {

        })
    },
    fetchContacts() {
      axios.get(`/properties/${this.property.id}/contacts.json`)
        .then(response => {
          this.contacts = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
  mounted() {
    this.fetchContacts()
  }
}
</script>

<style>
.action-link {
  cursor: pointer;
  text-decoration: none;
}
</style>