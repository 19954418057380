<template>
  <div class="stars-outer">
    <div class="stars-inner" :style="{width: `${starWidth()}%`}">
      <i class="fa fa-star"></i>
      <i class="fa fa-star"></i>
      <i class="fa fa-star"></i>
      <i class="fa fa-star"></i>
      <i class="fa fa-star"></i>
    </div>
    
    <i class="far fa-star"></i>
    <i class="far fa-star"></i>
    <i class="far fa-star"></i>
    <i class="far fa-star"></i>
    <i class="far fa-star"></i>
  </div>
</template>

<script>
export default {
  props: [
    "property"
  ],
  methods: {
    starWidth() {
      return (this.property.google_data["rating"] / 5) * 100
    }
  }
}
</script>

<style lang="scss" scoped>
  .stars-outer {
    display: inline-block;
    position: relative;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;

  }

  .stars-outer::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    /* content: "\f005 \f005 \f006 \f006 \f006"; */
    color: #f8ce0b;
  }

  .stars-inner {
    white-space: nowrap;
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
    width: 0;
    color: #f8ce0b;
  }

  .stars-inner::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    /* content: "\f005 \f005 \f005 \f005 \f005";*/
    color: #f8ce0b;
  }

</style>