<template>
  <div class="my-6 p-2 bg-light mt-2">
    <p class="mb-2"><b>{{ this.title }}</b></p>
  
    <form @submit.prevent="importCSV" v-if="!loading">
      <input type="file" v-on:change="handleFileUpload()" ref="file">
      
      <input type="submit" v-if="this.file.name.length > 0" name="commit" value="Import CSV" class="btn btn-secondary my-3">
    </form>
    
    <Spinner v-if="loading" />
  </div>
</template>

<script>
import axios from 'axios'
import Spinner from './Spinner.vue'

import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

export default {
  props: [
    "action",
    "title",
    "upload_type"
  ],
  components: {
    Spinner
  },
  data: function() {
    return {
      file: {
        name: ''
      },
      loading: false
    }
  },
  methods: {
    importCSV: function() {
      let formData = new FormData();
      formData.append('upload[files][]', this.file);
      formData.append('upload[upload_type]', this.upload_type);
      
      const notyf = new Notyf({
        position: {
          x: 'center',
          y: 'top',
        }
      });
      
      this.loading = true;

      axios.post( this.action, formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      ).then(response => {
        this.loading = false;
                
        notyf.success(response.data);
      })
      .catch(error => {
        this.loading = false;
        
        notyf.error(response.data);
      });
    },
    handleFileUpload(){
      this.file = this.$refs.file.files[0];
    }
  }
}
</script>